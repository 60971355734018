<template>
  <Consulta
    ref="filtroConsulta"
    titulo="Consulta de Usuários"
    :parametros="paramsFiltro"
    :colunas="headers"
    :ordenacao-colunas="sortBy"
    :consulta-callback="consulta"
  >
    <template v-slot:areaParametros>
      <v-row dense>
        <v-col cols="6">
          <v-text-field
            v-model="paramsFiltro.nome"
            dense
            label="Nome"
            name="nomeUsuario"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="paramsFiltro.cpf"
            v-mask="'###########'"
            :counter="11"
            dense
            label="CPF"
            maxlength="11"
            name="cpfUsuario"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="paramsFiltro.email"
            dense
            label="Email"
            name="emailUsuario"
            outlined
          ></v-text-field>
        </v-col>
        <v-col cols="6" lg="3">
          <v-select
            v-model="paramsFiltro.habilitado"
            :items="[
              { text: 'Sim', value: true },
              { text: 'Não', value: false }
            ]"
            clearable
            dense
            label="Habilitado"
            outlined
          ></v-select>
        </v-col>
        <v-col cols="6" lg="3">
          <v-select
            v-model="paramsFiltro.perfil"
            :items="[
              { text: 'Administrador', value: 'ADMINISTRADOR' },
              { text: 'Avaliador', value: 'AVALIADOR' },
              { text: 'Candidato', value: 'CANDIDATO' }
            ]"
            clearable
            dense
            label="Perfil"
            outlined
          ></v-select>
        </v-col>
      </v-row>
    </template>
    <template v-slot:areaParametrosBotoesExtra>
      <v-dialog
        v-model="dialog"
        width="500"
        @click:outside="cancelarEdicaoUsuario"
        scrollable
        persistent
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" color="primary">
            <v-icon left>mdi-plus-thick</v-icon>
            Adicionar
          </v-btn>
        </template>
        <v-card>
          <v-card-title class="primary justify-start white--text">
            {{ isCriandoUsuario ? "Novo Usuário" : "Atualizar Usuário" }}
            <v-spacer />
            <v-btn icon color="white" @click="cancelarEdicaoUsuario">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-form
              ref="edicaoUsuarioForm"
              @submit.prevent="salvarEdicaoUsuario"
              class="mt-3"
            >
              <v-text-field
                v-model="usuarioEditado.cpf"
                v-mask="'###########'"
                :counter="11"
                :rules="[campoObrigatorioRule, tamanhoCPFRule]"
                dense
                label="CPF *"
                maxlength="11"
                name="cpfCadastro"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="usuarioEditado.nome"
                :rules="[campoObrigatorioRule, formatoNomeRule]"
                dense
                label="Nome *"
                name="nomeCadastro"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="usuarioEditado.dataNascimento"
                v-mask="'##/##/####'"
                :rules="[campoObrigatorioRule, formatoDataRule]"
                dense
                label="Data de Nascimento *"
                name="dataNascimentoCadastro"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="usuarioEditado.mae"
                :rules="[campoObrigatorioRule, formatoNomeRule]"
                dense
                label="Mãe *"
                name="maeCadastro"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-model="usuarioEditado.email"
                :rules="[campoObrigatorioRule, formatoEmailRule]"
                dense
                label="Email *"
                name="emailCadastro"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-if="isCriandoUsuario"
                v-model="usuarioEditado.confirmacaoEmail"
                :rules="[
                  campoObrigatorioRule,
                  camposIguaisRule(
                    usuarioEditado.confirmacaoEmail,
                    usuarioEditado.email,
                    'Email diferente do informado!'
                  )
                ]"
                dense
                label="Confirmação de Email *"
                outlined
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-if="isCriandoUsuario"
                v-model="usuarioEditado.senha"
                :rules="[campoObrigatorioRule, tamanhoSenhaRule]"
                dense
                hint="Mínimo de 8 caracteres"
                label="Senha *"
                minlength="8"
                outlined
                type="password"
                validate-on-blur
              ></v-text-field>
              <v-text-field
                v-if="isCriandoUsuario"
                v-model="usuarioEditado.confirmacaoSenha"
                :rules="[
                  campoObrigatorioRule,
                  camposIguaisRule(
                    usuarioEditado.confirmacaoSenha,
                    usuarioEditado.senha,
                    'Senha diferente da informada!'
                  )
                ]"
                dense
                hint="Mínimo de 8 caracteres"
                label="Confirmação de Senha *"
                minlength="8"
                outlined
                type="password"
                validate-on-blur
              ></v-text-field>
              <v-select
                v-model="usuarioEditado.habilitado"
                :items="[
                  { text: 'Sim', value: true },
                  { text: 'Não', value: false }
                ]"
                :rules="[campoObrigatorioRule]"
                dense
                label="Habilitado *"
                outlined
              ></v-select>
              <v-select
                v-model="usuarioEditado.perfil"
                :items="[
                  { text: 'Administrador', value: 'ADMINISTRADOR' },
                  { text: 'Avaliador', value: 'AVALIADOR' },
                  { text: 'Candidato', value: 'CANDIDATO' }
                ]"
                :rules="[campoObrigatorioRule]"
                dense
                label="Perfil *"
                outlined
              ></v-select>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" @click.prevent="cancelarEdicaoUsuario">
                  Cancelar
                </v-btn>
                <v-btn color="primary" type="submit">
                  Salvar
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </template>
    <template v-slot:item.dataNascimento="{ item }">
      {{ tratarDataNumerica(item.dataNascimento) }}
    </template>
    <template v-slot:item.habilitado="{ item }">
      <v-simple-checkbox v-model="item.habilitado" @click="atualizaHabilitado(item)"></v-simple-checkbox>
    </template>
    <template v-slot:acoesMenu="{ item }">
      <v-btn block text @click="editarUsuario(item)">
        Editar
      </v-btn>
    </template>
  </Consulta>
</template>

<script>
import {mapActions} from "vuex";
import rules from "@/commons/rules";
import usuarioService from "@/services/usuario.service";
import Consulta from "@/components/pages/admin/consultas/Consulta";
import luxon from "@/core/luxon";

export default {
  name: "Usuarios",

  components: { Consulta },

  data() {
    return {
      dialog: false,
      headers: [
        { text: "Nome", value: "nome", mustSort: true },
        { text: "CPF", value: "cpf" },
        { text: "Data de Nascimento", value: "dataNascimento" },
        { text: "Email", value: "email" },
        { text: "Perfil", value: "perfil" },
        {
          text: "Habilitado",
          value: "habilitado",
          align: "center"
        }
      ],
      sortBy: ["nome"],
      paramsFiltro: {},
      usuarioEditado: {},
      consulta: usuarioService.recuperarFiltro
    };
  },

  computed: {
    isCriandoUsuario() {
      return !this.usuarioEditado.oid;
    }
  },

  methods: {
    ...mapActions("avisos", ["exibirAviso"]),
    ...rules,

    editarUsuario(usuario) {
      this.usuarioEditado = { ...usuario };
      this.usuarioEditado.dataNascimento = this.tratarDataNumerica(
        this.usuarioEditado.dataNascimento
      );
      this.dialog = true;
    },

    async salvarEdicaoUsuario() {
      if (this.$refs.edicaoUsuarioForm.validate()) {
        let loader = this.$loading.show();

        if (!this.isCriandoUsuario) {
          await usuarioService
            .atualizarUsuario(this.usuarioEditado)
            .then(() => {
              this.$refs.filtroConsulta.getDataFromApi();

              this.exibirAviso({
                mensagem: "Usuário atualizado com sucesso!",
                tipo: "success"
              });
            })
            .catch(error => {
              let mensagem;
              if (error.response.status === 404) {
                mensagem = "Usuário não encontrado";
              } else {
                mensagem = "Não foi possivel realizar alteração";
              }
              this.exibirAviso({
                mensagem: mensagem,
                tipo: "error"
              });
            });
        } else {
          await usuarioService
            .criarUsuario(this.usuarioEditado)
            .then(() => {
              if (this.$refs.filtroConsulta.mostrarResultado) {
                this.$refs.filtroConsulta.getDataFromApi();
              }

              this.exibirAviso({
                mensagem: "Usuário criado com sucesso!",
                tipo: "success"
              });
            })
            .catch(error => {
              if (error.response.status === 409) {
                this.exibirAviso({
                  mensagem:
                    "Não foi possível realizar o cadastro: CPF já cadastrado",
                  tipo: "error"
                });
              }
            });
        }
        this.cancelarEdicaoUsuario();

        loader.hide();
      }
    },

    tratarDataNumerica(data){
      if (data === undefined){
        return undefined
      }
      return luxon.dataConverter(data).toLocaleString();
    },

    cancelarEdicaoUsuario() {
      this.dialog = false;
      setTimeout(() => {
        this.$refs.edicaoUsuarioForm.reset();
        this.usuarioEditado = {};
      }, 500);
    },

    async atualizaHabilitado(item) {
      await usuarioService
          .atualizaHabilitado(item)
          .then(() => {
            this.$refs.filtroConsulta.getDataFromApi();
            this.exibirAviso({
              mensagem: item.habilitado ? "Usuário habilitado com sucesso!" : "Usuário desabilitado com sucesso!",
              tipo: "success"
            });
          }).catch(error => {
            let mensagem;
            if (error.response.status === 404) {
              mensagem = "Usuário não encontrado";
            } else {
              mensagem = "Não foi possivel alterar a situação de habilitado.";
            }
            this.exibirAviso({
              mensagem: mensagem,
              tipo: "error"
            });
          });
    }
  }
};
</script>
